import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import EarnRewardLanding from '../components/earn-rewards/earn-reward-landing';

const EarnRewards = () => (
  <Layout>
    <SEO title="Earn Rewards" keywords={[`gatsby`, `application`, `react`]} />
    <EarnRewardLanding />
  </Layout>
);

export default EarnRewards;
