import {useStaticQuery, graphql} from 'gatsby';
import * as drupalTransformer from "../libs/transform/drupal";
import {formatFieldsValue} from "../libs/formatFromGraph";


export const usePagesRegistration = () => {
  const data = usePagesRegistrationQuery();
  const dataPage = formatFieldsValue(data);
  dataPage.configPagesRegistration = drupalTransformer.transformNode(dataPage.configPagesRegistration)
  return dataPage;
};


const usePagesRegistrationQuery = () => {
  return useStaticQuery(graphql`
      query {
          configPagesRegistration {
              field_agreement_label {
                  value
              }
              field_login_description {
                  value
              }
              field_label
              field_link {
                  title
                  uri
              }
              field_login_page_register_descri {
                  value
              }
              field_login_page_register_title {
                  value
              }
              field_login_title
              field_receive_emails_label {
                  value
              }
              field_registration_description {
                  value
              }
              field_registration_title {
                  value
              }
              field_earn_title {
                  value
              }
              field_earn_logged_title {
                  value
              }
              field_earn_logged_sub_title
              field_earn_logged_description {
                  value
              }
              field_earn_description {
                  value
              }
              field_hte_item_title_3
              field_hte_item_title
              field_app_title
              field_app_description
              field_hte_item_description_3 
              field_hte_item_description_2 
              field_hte_item_description 
              field_how_to_earn_title
              field_hte_item_image {
                  alt
              }
              field_hte_item_image_2 {
                  alt
              }
              field_hte_item_image_3 {
                  alt
              }
              field_rfs_title
              field_hte_item_title_2
              relationships {
                  field_app_image {
                      localFile {
                          childImageSharp {
                              fluid {
                                  srcSet
                                  src
                                  base64
                                  aspectRatio
                                  sizes
                              }
                          }
                      }
                  }
                  field_hte_item_image {
                      localFile {
                          childImageSharp {
                              fluid {
                                  srcSet
                                  src
                                  base64
                                  aspectRatio
                                  sizes
                              }
                          }
                      }
                  }
                  field_hte_item_image_2 {
                      localFile {
                          childImageSharp {
                              fluid {
                                  srcSet
                                  src
                                  base64
                                  aspectRatio
                                  sizes
                              }
                          }
                      }
                  }
                  field_hte_item_image_3 {
                      localFile {
                          childImageSharp {
                              fluid {
                                  srcSet
                                  src
                                  base64
                                  aspectRatio
                                  sizes
                              }
                          }
                      }
                  }
                  field_hte_logos {
                      localFile {
                          childImageSharp {
                              fluid {
                                  srcSet
                                  src
                                  base64
                                  aspectRatio
                                  sizes
                              }
                          }
                      }
                  }
                  field_rfs_image {
                      localFile {
                          childImageSharp {
                              fluid {
                                  srcSet
                                  src
                                  base64
                                  aspectRatio
                                  sizes
                              }
                          }
                      }
                  }
              }
          }
      }
  `);
};
