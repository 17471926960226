// Libs
import React from 'react';
import styles from './earn-card.module.scss';
import Img from 'gatsby-image';
import * as PropTypes from 'prop-types';

const EarnCard = ({ card }) => {
  return (
    <div className={styles.earnCard}>
      <div className={styles.earnCardTitle} dangerouslySetInnerHTML={{ __html: card.title }}/>
      <div className={styles.earnCardImage}>
        <Img className={styles.earnCardImg} fluid={card.image.fluid}/>
      </div>
      <div className={styles.earnCardDescription}>{card.description}</div>
    </div>
  );
};

EarnCard.propTypes = {
  card: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default EarnCard;
