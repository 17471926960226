function sortByWeight(a, b) {
  if (a.weight < b.weight) {
    return -1;
  }
  if (a.weight > b.weight) {
    return 1;
  }
  return 0;
}

export function getCategorizedContent(data) {
  let result = {};
  for (let i = 0; i < data.length; i++) {
    if (!result[data[i].headline]) {
      result[data[i].headline] = [];
    }
    result[data[i].headline].push(data[i]);
    result[data[i].headline].sort(sortByWeight);
  }

  return result;
}

export function getCategorizedContentByTitle(data) {
  let result = {};
  for (let i = 0; i < data.length; i++) {
    if (!result[data[i].title]) {
      result[data[i].title] = [];
    }
    result[data[i].title].push(data[i]);
    result[data[i].title].sort(sortByWeight);
  }

  return result;
}

export function getContentSubItems(data, sectionTitle, numElements = -1) {
  let all = getCategorizedContent(data);
  if (numElements !== -1) {
    return all[sectionTitle].slice(0, numElements) || [];
  } else {
    return all;
  }
}

export function generateArrayOfFixedSizeArrays(arr, size) {
  if (arr.length === 0) {
    return arr;
  }
  return [arr.slice(0, size), ...generateArrayOfFixedSizeArrays(arr.slice(size), size)];
}
