const transformMenu = (raw = {}) => {
  const formatted = {}

  if (raw) {
    // Transform stuff.
  }

  return formatted
}

module.exports = transformMenu
