// Libs
import React, {useContext} from 'react';
import { uniqueId } from 'lodash';
import { Link } from 'gatsby';

// Deps
//Assets.
import styles from './static-content-list.module.scss';
import cardStyle from './static-content-card.module.scss';

import { generateArrayOfFixedSizeArrays } from '../../../libs/static-content/static-content-utils';
import {AppContext} from "../../layout/AppLayout";

const StaticContentList = ({ listingTitle, items, numItemsPerLine, viewAllLink }) => {
  const displayClass =
    numItemsPerLine === '1'
      ? styles.staticContentListOneItemPerRow
      : styles.staticContentListThreeItemsPerRow;

  const arrayOfArrays = generateArrayOfFixedSizeArrays(items, numItemsPerLine);
  const isApp = useContext(AppContext);

  const titles = arrayOfArrays.map((set, titleIndex) => {
    return (
      <div key={uniqueId('static-section')}>
        <div className={displayClass}>
          {set
            .filter((item) => item.title !== '')
            .map((item, i) => {
              const cardDisplayClass =
                numItemsPerLine === '1'
                  ? cardStyle.staticContentCardFull
                  : cardStyle.staticContentCardOneThird;

              return (
                <div className={cardDisplayClass} key={uniqueId('static-title')}>
                  <h1
                    className={cardStyle.staticContentCardTextTitle}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                </div>
              );
            })}
        </div>
        <div className={displayClass}>
          {set.map((item, i) => {
            const cardBodyDisplayClass =
              numItemsPerLine === '1'
                ? cardStyle.staticContentCardFull
                : cardStyle.staticContentCardOneThird;

            return (
              <div className={cardBodyDisplayClass} key={uniqueId('static-body')}>
                <h2
                  className={cardStyle.staticContentCardTextTitle}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <div
                  className={cardStyle.staticContentCardTextBody}
                  dangerouslySetInnerHTML={{ __html: item.body }}
                />
              </div>
            );
          })}
        </div>
        <div className={styles.staticContentListSpacer}/>
      </div>
    );
  });

  return (
    <div className={styles.staticContentListSection}>
      <div className={styles.staticContentListContainer}>
        <div className={styles.staticContentListHeaderContainer}>
          {!isApp && <h2 className={viewAllLink !== '' ? styles.staticContentListTitle : styles.staticContentListTitleSingle}>{listingTitle}</h2>}
          {viewAllLink !== '' && (
            <div className={styles.faqListViewAll}>
              <Link to={viewAllLink}>View All</Link>
            </div>
          )}
        </div>
        {titles}
      </div>
    </div>
  );
};

export default StaticContentList;
