import React from 'react';
import UserPointsDiagram from '../common/user-points/user-points-diagram';
//assets
import styles from './earn-reward-user-points.module.scss';

const EarnRewardUserPoints = ({ customer }) => {
  return (
    <div className={styles.earnRewardUserPoints}>
      <div className={styles.earnRewardUserPointsContent}>
        <UserPointsDiagram points={customer.PointsBalance} />
        <div className={styles.earnRewardUserPointsCta}></div>
      </div>
    </div>
  );
};

export default EarnRewardUserPoints;
