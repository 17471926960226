// Libs
import React, { useEffect } from 'react';
// State
import data from '../../mocks/earn-reward-landing';
import { useStateValue } from '../../store/state';
import useCustomer from '../../hooks/useCustomer';
// Deps
import StaticContentList from '../common/static-content/static-content-list';
import PrimarySignupCTA from './primary-signup-CTA';
import LongosAppCTA from './longos-app-cta';
import HowToEarn from './how-to-earn';
import Redeem from './redeem';
import EarnRewardUserPoints from './earn-reward-user-points';
import ManageUserPoints from './manage-user-points';
import { getContentSubItems } from '../../libs/static-content/static-content-utils';
// Assets
import styles from './earn-reward-landing.module.scss';
import locale from '../../locale/locale.json';
import {usePagesRegistration} from "../../hooks/usePagesRegistration";
import {useFAQQuery} from "../../hooks/useFAQPageQuery";

const EarnRewardLanding = () => {
  const [state] = useStateValue();
  const { getCustomer } = useCustomer();
  const {configPagesFaq: {items: faqs} } = useFAQQuery();
  const {configPagesRegistration: pageData} = usePagesRegistration();

  const redeem = {
    ...data.redeem,
    title: pageData.rfsTitle,
    image: pageData.rfsImage,
  }
  const app = {
    title: pageData.appTitle,
    summary: pageData.appDescription,
    image: pageData.appImage.fluid
  }

  useEffect(() => {
    getCustomer();
  }, [state.user.access_token]);

  return (
    <>
      <div className={styles.earnRewardLandingColoredWrapper}>
        <div className={styles.earnRewardLandingContainer}>
          {state.user.access_token && (
            <div className={styles.earnRewardLandingContainerPoints}>
              <h1 className={styles.earnRewardUserPointsTitle} dangerouslySetInnerHTML={{__html: `${pageData.earnLoggedTitle}<br />
                ${state.customer.FirstName}`}}/>
              <EarnRewardUserPoints customer={state.customer} />
              <ManageUserPoints points={state.customer.CardBalance} />
            </div>
          )}
        </div>
        <div className={styles.earnRewardLandingContainer}>
          {!state.user.access_token && <PrimarySignupCTA cta={data.signupCta} />}
        </div>
        <div className={styles.earnRewardLandingContainer}>
          <HowToEarn />
          <Redeem redeem={redeem} />
        </div>
      </div>
      <div className={styles.earnRewardLandingWhiteWrapper}>
        <div className={styles.earnRewardLandingContainer}>
          <LongosAppCTA cta={{...data.appCta, ...app}} />
          <StaticContentList
            listingTitle={locale.faqs.title}
            items={getContentSubItems(faqs, 'Thank You Rewards', 3)}
            showViewAll={true}
            numItemsPerLine="3"
            viewAllLink="/faqs"
          />
        </div>
      </div>
    </>
  );
};

export default EarnRewardLanding;
