import { useMemo } from 'react';

export const useUserPointsDataForDiagram = (points) => {
  const savePoints = () => {
    // Which based points to use, either 2200 for first level, to 2000 for sub levels.
    const pointsBase = points < 2200 ? 2200 : 2000;
    // points < 2200, level 0
    // points < 2400, level 1
    // points < 4000, level 2
    // points < 5000, level 2
    // points < 6000, level 3
    const pointsCurrent = points < 2200 ?
      0 :
      Math.floor(points / pointsBase);
    // How many points till the next level?
    const pointsNext = (pointsCurrent + 1) * pointsBase;
    // How many points wre needed for the last cap?
    const pointsPrevious = pointsCurrent === 1 ? 2200 : pointsCurrent * 2000;
    // How many points are needed to reach the next level
    const pointsTillNextLevel = pointsBase - (points % pointsBase);
    // How much % until the next level
    const percentToNextLevel = Math.round(100 - (pointsTillNextLevel / pointsBase) * 100);
    // The dollar amount for the current points level the user has
    const dollarsCurrent = pointsCurrent * 10;
    // The dollar amount for the next points level
    const dollarsNext = (pointsCurrent + 1) * 10;

    return {
      pointsCurrent,
      pointsNext,
      pointsPrevious,
      pointsTillNextLevel,
      percentToNextLevel,
      dollarsCurrent,
      dollarsNext,
    };
  };
  return useMemo(() => savePoints(points), [points]);
};
