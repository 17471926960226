// Libs
import React from 'react';
import styles from './redeem.module.scss';
import Img from 'gatsby-image';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import * as PropTypes from 'prop-types';

const Redeem = ({ redeem }) => {
  const image = redeem.image;
  return (
    <div className={styles.redeem}>
      <div className={styles.redeemImage}>
        <Img fluid={image.fluid} />
      </div>
      <div className={styles.redeemContainer}>
        <div className={styles.redeemTitle}>{redeem.title}</div>
        <ul className={styles.redeemList}>
          {redeem.list.map((item, key) => (
            <li key={key} className={styles.redeemListItem}>
              {item.title}
            </li>
          ))}
        </ul>
        <div className={styles.redeemDescription}>{redeem.description}</div>
      </div>
    </div>
  );
};

Redeem.propTypes = {
  redeem: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default Redeem;
