import React, { useEffect, useState } from 'react';

const ProgressProvider = ({ valueStart = 0, valueEnd, children }) => {
  const [value, setValue] = useState(valueStart);
  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setValue(valueEnd);
    }, 0);
    return () => {
      window.clearTimeout(timeout);
    };
  }, [valueStart, valueEnd]);
  return <>{children(value)}</>;
};

export default ProgressProvider;
