// Libs
import React from 'react';
import { get } from 'lodash';
import * as PropTypes from 'prop-types';
// Deps
import Img from 'gatsby-image';
// State
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
// Assets
import styles from './longos-app-cta.module.scss';
import locale from '../../locale/locale';

const LongosAppCta = ({ cta }) => {
  const images = {
    cta: useImageStaticQuery(cta.img),
    landingCta: useImageStaticQuery('landing--get-the-longos.jpg'),
    qrCode: useImageStaticQuery('Longo_app_QR-Code.png'),
    appStore: useImageStaticQuery('landing--get-the-longos--appstore.png'),
    googlePlay: useImageStaticQuery('landing--get-the-longos--googleplay.png'),
  };

  const imgGetTheLongos = cta.image ? cta.image : get(cta.img ? images.cta : images.landingCta, 'childImageSharp.fluid', null);
  // console.log(cta);

  return (
    <section className={styles.longosAppCtaBlock}>
      {imgGetTheLongos !== null && (
        <div className={styles.longosAppCtaImage}>
          {imgGetTheLongos !== null && <Img
            fluid={imgGetTheLongos}
            className={styles.longosAppCtaMainImage}
            alt="Get the Longos App"
          />}
        </div>
      )}
      <div className={styles.longosAppCtaContent}>
        {cta.title && <h3 className={styles.longosAppCtaTitle}>{cta.title}</h3>}
        <p className={styles.longosAppCtaDescription}>{cta.summary}</p>
        <div className={styles.longosAppCtaLinks}>
        <Img
              fluid={images.qrCode.childImageSharp.fluid}
              className={styles.imgLongosAppCtaQr}
              alt={locale.longosAppCta.getAppFromAppStore}
          />
          <a
            href={cta.appleLink}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.longosAppCtaLink}
          >
            <Img
              fluid={images.appStore.childImageSharp.fluid}
              className={styles.imgLongosAppCtaMarketLogo}
              alt={locale.longosAppCta.getAppFromAppStore}
            />
          </a>
          <a
            href={cta.googleLink}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.longosAppCtaLink}
          >
            <Img
              fluid={images.googlePlay.childImageSharp.fluid}
              className={styles.imgLongosAppCtaMarketLogo}
              alt={locale.longosAppCta.getAppFromPlayStore}
            />
          </a>
        </div>
      </div>
    </section>
  );
};

LongosAppCta.propTypes = {
  cta: PropTypes.shape({
    title: PropTypes.string,
    summary: PropTypes.string.isRequired,
  }).isRequired,
};

export default LongosAppCta;
