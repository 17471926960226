import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import ProgressProvider from './ProgressProvider';
//assets
import styles from './user-points-diagram.module.scss';
import { useUserPointsDataForDiagram } from '../../../libs/useUserPointsDataForDiagram';

const UserPointsDiagram = ({ points }) => {
  // getUserPointsDataForDiagram is a black-box function with calculation logic.
  // return:
  // 1) Points on next lvl.
  // 2) Current lvl.
  // 3) How much to earn to achieve current lvl.
  // 4) Current percents on lvl.
  const {
    pointsNext,
    pointsPrevious,
    pointsTillNextLevel,
    percentToNextLevel,
    dollarsCurrent,
    dollarsNext,
  } = useUserPointsDataForDiagram(points);
  return (
    <div className={styles.userPointsDiagram}>
      <div className={styles.userPointsDiagramTitle}>
        {/* Only should the current level of earned points if available.*/
          pointsPrevious > 0 && <>
            {pointsPrevious} = ${dollarsCurrent}
          </>}
      </div>
      <ProgressProvider valueStart={0} valueEnd={percentToNextLevel}>
        {(value) => (
          <CircularProgressbarWithChildren
            value={value}
            maxValue={100}
            strokeWidth={5}
            styles={buildStyles({
              // Rotation of path and trail, in number of turns (0-1)
              rotation: 0,
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'round',
              // How long animation takes to go from one percentage to another, in seconds
              pathTransitionDuration: 1,

              // Colors
              pathColor: `#74aa50`,
              trailColor: '#f7f7f7',
              backgroundColor: '#74aa50',
            })}
          >
            <div className={styles.userPointsDiagramContent}>
              <div className={styles.userPointsDiagramContentTitle}>Your points</div>
              <div className={styles.userPointsDiagramContentPoints}>{points}</div>
              <div className={styles.userPointsDiagramContentNextReward}>
                Earn {pointsTillNextLevel} points to reach your next reward.
              </div>
            </div>
          </CircularProgressbarWithChildren>
        )}
      </ProgressProvider>
      <div className={styles.userPointsDiagramNextLvl}>
        Next level: {pointsNext} = ${dollarsNext} off
      </div>
    </div>
  );
};

export default UserPointsDiagram;
