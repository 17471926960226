// Libs
import React from 'react';
import { Link } from 'gatsby';
// State
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
// Deps
import { useUserPointsDataForDiagram } from '../../libs/useUserPointsDataForDiagram';
// Assets
import styles from './manage-user-points.module.scss';
import locale from '../../locale/locale';
import {usePagesRegistration} from "../../hooks/usePagesRegistration";

const ManageUserPoints = ({ points }) => {
  const heartIcon = useImageStaticQuery('icons/icon-heart.svg');
  // console.log(heartIcon)
  const { pointsCurrent, dollarsCurrent } = useUserPointsDataForDiagram(points);
  const {configPagesRegistration: pageData} = usePagesRegistration();
  // console.log(lvl)
  return (
    <div className={styles.manageUserPoints}>
      {pointsCurrent > 0 && (
        <div className={styles.manageUserPointsEarn}>
          <img src={heartIcon.publicURL} alt="reward indicator"/>
          <div className={styles.manageUserPointsEarnContent}>
            <div className={styles.manageUserPointsEarnContentTitle}>${dollarsCurrent} earned</div>
            <div>{locale.pages.earnRewards.manageUserPoints.redeemAndSave}</div>
          </div>
        </div>
      )}
      <div className={styles.manageUserPointsDetails}>
        <h2>{pageData.earnLoggedSubTitle}</h2>
        <p>{pageData.earnLoggedDescription}</p>
        <Link to="/account-points">{locale.pages.earnRewards.manageUserPoints.buttonLabel}</Link>
      </div>
    </div>
  );
};

export default ManageUserPoints;
