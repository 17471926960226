// Libs
import React from 'react';
import styles from './how-to-earn.module.scss';
import EarnCard from './earn-card';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import Img from 'gatsby-image';
import {usePagesRegistration} from "../../hooks/usePagesRegistration";

const HowToEarn = () => {
  const {configPagesRegistration: pageData} = usePagesRegistration();
  const items = [
    {
      title: pageData.hteItemTitle,
      image: pageData.hteItemImage,
      description: pageData.hteItemDescription,
    },
    {
      title: pageData.hteItemTitle2,
      image: pageData.hteItemImage2,
      description: pageData.hteItemDescription2,
    },
    {
      title:pageData.hteItemTitle3,
      image: pageData.hteItemImage3,
      description:pageData.hteItemDescription3,
    },
  ];
  const logos = pageData.hteLogos.map( logo => {
    return {logo: logo}
  });
  return (
    <div className={styles.howToEarn}>
      <div className={styles.howToEarnContainer}>
        <div className={styles.howToEarnHeaderContainer}>
          <h2 className={styles.howToEarnTitle}>{pageData.howToEarnTitle}</h2>
        </div>
        <div className={styles.howToEarnListContainer}>
          {items.map((item, i) => {
            return <EarnCard key={i} card={item} />;
          })}
        </div>
      </div>
      <div className={styles.howToEarnLogosContainer}>
        {logos.map((item, i) => {
          return (
            <Img className={styles.earnCardImg} fluid={item.logo.fluid} key={i} />
          );
        })}
      </div>
    </div>
  );
};

export default HowToEarn;
