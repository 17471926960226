import { useStaticQuery, graphql } from 'gatsby';
import {drupalTransformer} from '../libs/transform/index'
import {map} from 'lodash'

export const useFAQQuery = () => {
  const data = useFAQ();
  let pageData = [];
  map(data.configPagesFaq.relationships, (item, key) => {
    let transformKey;
    switch (key) {
      case 'field_faq':
        transformKey = 'Thank You Rewards'
        break
      case 'field_faq_2':
        transformKey = 'Loft Cooking School'
        break
      case 'field_faq_3':
        transformKey = 'Customer Care'
        break
      case 'field_faq_4':
        transformKey = 'Longo\'s Family Charitable Foundation'
        break
    }
    const transformedItem = item.map((faqItem) => {
      return  {body: faqItem.field_answer, title: faqItem.title, headline: transformKey, weight: 0};
    });
    pageData = [...pageData, ...transformedItem];
  })
  data.configPagesFaq.items = pageData;
  const dataTransform = drupalTransformer.transformNode(data);

  return dataTransform

};

const useFAQ = () => {
  return useStaticQuery(graphql`
    query test {
        configPagesFaq {
            field_label
            field_fa {
                value
            }
            relationships {
                field_faq {
                    title:field_type_label
                    field_answer {
                        value
                    }
                }
                field_faq_2 {
                    title:field_type_label
                    field_answer {
                        value
                    }
                }
                field_faq_3 {
                    title:field_type_label
                    field_answer {
                        value
                    }
                }
                field_faq_4 {
                    title:field_type_label
                    field_answer {
                        value
                    }
                }
            }
        }
    }

  `);
};
