import React from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
//assets
import styles from './primary-signup-CTA.module.scss';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import Img from 'gatsby-image';
import pageAliases from '../../data/pageAliases';
import { pushEvent } from '../../libs/dataLayer';
import {usePagesRegistration} from "../../hooks/usePagesRegistration";
import SiteLink from "../common/site-link";

const PrimarySignupCTA = ({ cta }) => {
  const imgPlaceholderXL = useImageStaticQuery('earn-rewards/header-background-xl.png');
  const imgPlaceholderL = useImageStaticQuery('earn-rewards/header-background-lg.png');
  const imgPlaceholderS = useImageStaticQuery('earn-rewards/header-background-small.png');
  const {configPagesRegistration: pageData} = usePagesRegistration();

  const handleClick = (e) => {
    pushEvent('interaction', 'Sigup clicked from Earn Rewards Page.', 'Earn Rewards');
  };

  return (
    <div className={styles.primarySignupCta}>
      <div className={styles.primarySignupCtaImageXl}>
        <Img fluid={imgPlaceholderXL.childImageSharp.fluid}/>
      </div>
      <div className={styles.primarySignupCtaImageLg}>
        <Img fluid={imgPlaceholderL.childImageSharp.fluid}/>
      </div>
      <div className={styles.primarySignupCtaImageS}>
        <Img fluid={imgPlaceholderS.childImageSharp.fluid}/>
      </div>

      <div className={styles.primarySignupCtaContainer}>
        <div className={styles.primarySignupCtaContent}>
          <h1 className={styles.primarySignupCtaTitle} dangerouslySetInnerHTML={{ __html: pageData.earnTitle }}/>
          <div className={styles.primarySignupCtaDescription} dangerouslySetInnerHTML={{ __html: pageData.earnDescription }}></div>
          <Link onClick={handleClick} className={styles.primarySignupCtaButton} to={pageAliases.register}>
            {' '}
            {cta.signUpButton}
          </Link>
          <div className={styles.primarySignupCtaLogin}>
            {pageData.label}
            <SiteLink href={pageData.link.uri}>
              {pageData.link.title}
            </SiteLink>
          </div>
        </div>
      </div>
    </div>
  );
};

PrimarySignupCTA.propTypes = {
  cta: PropTypes.object.isRequired,
};

export default PrimarySignupCTA;
